import { Paper, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const Title = styled(Typography)({
  fontWeight: 600,
  marginBottom: "50px",
});

export const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "paperPadding",
})`
  (({ paperPadding }) => ({
    borderRadius: 24,
    padding: paperPadding || "32px 25px",
  }));`;
