import React, { useState } from "react";
import Container from "../components/Container";
import { Typography, Stack, TextField, Alert } from "@mui/material";
import algodClient from "../config/initAlgodClient";
import algosdk from "algosdk";
import LoadingButton from "@mui/lab/LoadingButton";
import { Buffer } from "buffer";
import {
  mparamsQCADIssuer,
  mparamsQCADManager,
  mparamsKCADIssuer,
  mparamsKCADManager,
} from "../config/multisigs";

const Transfer = () => {
  window.Buffer = window.Buffer || require("buffer").Buffer;
  const [values, setValues] = useState({
    from: "",
    to: "",
    amount: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [outputTxn, setOutputTxn] = useState("");

  const generateAlgoTransferTransaction = async (from, to, amount) => {
    try {
      const QCADConfig = {
        issuer: algosdk.multisigAddress(mparamsQCADIssuer),
        manager: algosdk.multisigAddress(mparamsQCADManager),
      };
      const KCADConfig = {
        issuer: algosdk.multisigAddress(mparamsKCADIssuer),
        manager: algosdk.multisigAddress(mparamsKCADManager),
      };
      let mparams;
      if (from === QCADConfig.issuer) {
        mparams = mparamsQCADIssuer;
      } else if (from === QCADConfig.manager) {
        mparams = mparamsQCADManager;
      } else if (from === KCADConfig.issuer) {
        mparams = mparamsKCADManager;
      } else if (from === KCADConfig.manager) {
        mparams = mparamsKCADManager;
      } else {
        throw new Error(
          "Invalid Sender. Please provide issuer or manager address."
        );
      }
      const params = await algodClient.getTransactionParams().do();
      const sender = from;
      const recipient = to;
      if (recipient === "" || recipient === undefined) {
        throw new Error(
          "Recipient undefined. Please provide recipient account address."
        );
      }
      const closeRemainderTo = undefined;
      const calcAmount = Number(amount) * 1000000;
      const note = undefined;

      const rawTxn = algosdk.makePaymentTxnWithSuggestedParams(
        sender,
        recipient,
        calcAmount,
        closeRemainderTo,
        note,
        params
      );
      const encodedRawTxn = algosdk.createMultisigTransaction(rawTxn, mparams);
      return Buffer.from(encodedRawTxn).toString("hex");
    } catch (err) {
      throw err;
    }
  };

  const handleRequest = async () => {
    try {
      setSubmitting(true);
      if (!values.amount || Number(values.amount) <= 0) {
        throw new Error("Invalid amount entered.");
      }
      const encodedRawTxn = await generateAlgoTransferTransaction(
        values.from,
        values.to,
        values.amount
      );
      setOutputTxn(encodedRawTxn);
      setSuccessMessage(`Generated transfer transaction successfully!`);
      setSubmitting(false);
    } catch (err) {
      setSuccessMessage(err.message);
      setSubmitting(false);
    }
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValues({
      ...values,
      [event.target.name]: newValue,
    });
  };

  return (
    <>
      <Container maxWidth="100%" paperPadding={30} style={{ padding: "10px" }}>
        {successMessage && <Alert severity="info">{successMessage}</Alert>}
        <Typography variant="h3" style={{ padding: "40px" }}>
          Transfer ALGO
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>From Address</Typography>
          <TextField
            value={values.from}
            onChange={handleChange}
            name="from"
            placeholder="Sender Address"
            style={{ width: "800px" }}
            required
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>To Address</Typography>
          <TextField
            value={values.to}
            onChange={handleChange}
            name="to"
            placeholder="Recipient Address"
            style={{ width: "800px" }}
            required
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Amount (in ALGO)</Typography>
          <TextField
            value={values.amount}
            onChange={handleChange}
            name="amount"
            placeholder="Amount"
            style={{ width: "800px" }}
            required
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <LoadingButton
            variant="contained"
            fullWidth
            size="large"
            loading={submitting}
            onClick={handleRequest}
            style={{ backgroundColor: "#282c34" }}
          >
            Generate Transfer Transaction
          </LoadingButton>
        </Stack>
        {outputTxn && (
          <>
            <Typography
              variant="h6"
              style={{ paddingLeft: "40px", paddingTop: "40px" }}
            >
              Output Transaction:
            </Typography>
            <Typography style={{ padding: "40px", overflowWrap: "break-word" }}>
              {outputTxn}
            </Typography>
          </>
        )}
      </Container>
    </>
  );
};

export default Transfer;
