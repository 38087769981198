import React, { useState } from "react";
import Container from "../components/Container";
import {
  Typography,
  Stack,
  TextField,
  Alert,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import algodClient from "../config/initAlgodClient";
import algosdk from "algosdk";
import LoadingButton from "@mui/lab/LoadingButton";
import { Buffer } from "buffer";
import {
  mparamsQCADIssuer,
  mparamsQCADManager,
  mparamsKCADIssuer,
  mparamsKCADManager,
} from "../config/multisigs";

const Mint = () => {
  window.Buffer = window.Buffer || require("buffer").Buffer;
  const [values, setValues] = useState({
    assetId: "",
    to: "",
    amount: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [outputTxn, setOutputTxn] = useState("");
  const [issuerConfig, setIssuerConfig] = useState("QCAD");
  const QCADConfig = {
    issuer: algosdk.multisigAddress(mparamsQCADIssuer),
    manager: algosdk.multisigAddress(mparamsQCADManager),
  };
  const KCADConfig = {
    issuer: algosdk.multisigAddress(mparamsKCADIssuer),
    manager: algosdk.multisigAddress(mparamsKCADManager),
  };
  const [issuerAddress, setIssuerAddress] = useState(QCADConfig.issuer);

  const switchConfiguration = (event) => {
    if (event.target.value === QCADConfig.issuer) {
      setIssuerConfig("QCAD");
      setIssuerAddress(QCADConfig.issuer);
      setSuccessMessage("");
      setOutputTxn("");
    }
    if (event.target.value === KCADConfig.issuer) {
      setIssuerConfig("KCAD");
      setIssuerAddress(KCADConfig.issuer);
      setSuccessMessage("");
      setOutputTxn("");
    }
  };

  const generateMintTransaction = async (assetId, to, amount) => {
    try {
      const params = await algodClient.getTransactionParams().do();
      const sender = issuerAddress;
      const recipient = to;
      if (recipient === "" || recipient === undefined) {
        throw new Error(
          "Recipient undefined. Please provide recipient account address."
        );
      }
      const revocationTarget = undefined;
      const closeRemainderTo = undefined;
      const calcAmount = Number(amount) * 100;
      const assetID = Number(assetId);
      const note = undefined;

      const rawTxn = algosdk.makeAssetTransferTxnWithSuggestedParams(
        sender,
        recipient,
        closeRemainderTo,
        revocationTarget,
        calcAmount,
        note,
        assetID,
        params
      );
      const mparamsIssuer =
        issuerConfig === "QCAD" ? mparamsQCADIssuer : mparamsKCADIssuer;
      const encodedRawTxn = algosdk.createMultisigTransaction(
        rawTxn,
        mparamsIssuer
      );
      return Buffer.from(encodedRawTxn).toString("hex");
    } catch (err) {
      throw err;
    }
  };

  const handleRequest = async () => {
    try {
      setSubmitting(true);
      if (!values.amount || Number(values.amount) <= 0) {
        throw new Error("Invalid amount entered.");
      }
      const encodedRawTxn = await generateMintTransaction(
        values.assetId,
        values.to,
        values.amount
      );
      setOutputTxn(encodedRawTxn);
      setSuccessMessage(`Generated mint transaction successfully!`);
      setSubmitting(false);
    } catch (err) {
      setSuccessMessage(err.message);
      setSubmitting(false);
    }
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValues({
      ...values,
      [event.target.name]: newValue,
    });
  };

  return (
    <>
      <Container maxWidth="100%" paperPadding={30} style={{ padding: "10px" }}>
        {successMessage && <Alert severity="info">{successMessage}</Alert>}
        <Typography variant="h3" style={{ padding: "40px" }}>
          Mint
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <FormControl fullWidth>
            <InputLabel id="select-config">Configuration</InputLabel>
            <Select
              labelId="select-config"
              id="config"
              value={issuerAddress}
              label="Configuration"
              onChange={switchConfiguration}
            >
              <MenuItem value={QCADConfig.issuer}>{QCADConfig.issuer}</MenuItem>
              <MenuItem value={KCADConfig.issuer}>{KCADConfig.issuer}</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>From Address</Typography>
          <Typography>{issuerAddress}</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>To Address</Typography>
          <TextField
            value={values.to}
            onChange={handleChange}
            name="to"
            placeholder="Recipient Address"
            style={{ width: "800px" }}
            required
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Asset ID</Typography>
          <TextField
            value={values.assetId}
            onChange={handleChange}
            name="assetId"
            placeholder="Algorand Asset ID"
            style={{ width: "800px" }}
            required
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Amount</Typography>
          <TextField
            value={values.amount}
            onChange={handleChange}
            name="amount"
            placeholder="Amount"
            style={{ width: "800px" }}
            required
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <LoadingButton
            variant="contained"
            fullWidth
            size="large"
            loading={submitting}
            onClick={handleRequest}
            style={{ backgroundColor: "#282c34" }}
          >
            Generate Mint Transaction
          </LoadingButton>
        </Stack>
        {outputTxn && (
          <>
            <Typography
              variant="h6"
              style={{ paddingLeft: "40px", paddingTop: "40px" }}
            >
              Output Transaction:
            </Typography>
            <Typography style={{ padding: "40px", overflowWrap: "break-word" }}>
              {outputTxn}
            </Typography>
          </>
        )}
      </Container>
    </>
  );
};

export default Mint;
