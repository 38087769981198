import React, { useState } from "react";
import Container from "../components/Container";
import { Typography, Stack, TextField, Alert } from "@mui/material";
import algodClient from "../config/initAlgodClient";
import algosdk from "algosdk";
import LoadingButton from "@mui/lab/LoadingButton";
import { Buffer } from "buffer";
import TransportWebUSB from "@ledgerhq/hw-transport-webusb";
import Algorand from "@ledgerhq/hw-app-algorand";
import { listen } from "@ledgerhq/logs";

const OptIn = () => {
  window.Buffer = window.Buffer || require("buffer").Buffer;
  const [assetId, setAssetId] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [outputTxn, setOutputTxn] = useState("");
  const [connectedWallet, setConnectedWallet] = useState("");
  const [derivationPath, setDerivationPath] = useState("44'/283'/0'/0/0");

  const handleConnection = async () => {
    try {
      setSubmitting(true);
      TransportWebUSB.create().then(async (transport) => {
        listen((log) => console.log(log));
        const algorand = new Algorand(transport);
        const { address } = await algorand.getAddress(derivationPath, true);
        setConnectedWallet(address);
      });
      setSubmitting(false);
    } catch (err) {
      setSuccessMessage(err.message);
      setSubmitting(false);
    }
  };

  const generateAlgoOptInTransaction = async (assetId) => {
    try {
      if (!connectedWallet) {
        throw new Error("Please connect wallet first.");
      }
      const params = await algodClient.getTransactionParams().do();
      const sender = connectedWallet;
      const recipient = connectedWallet;
      const revocationTarget = undefined;
      const closeRemainderTo = undefined;
      const note = undefined;
      const assetID = Number(assetId);
      const amount = 0;

      const rawTxn = algosdk.makeAssetTransferTxnWithSuggestedParams(
        sender,
        recipient,
        closeRemainderTo,
        revocationTarget,
        amount,
        note,
        assetID,
        params
      );
      const encodedRawTxn = algosdk.encodeUnsignedTransaction(rawTxn);
      return Buffer.from(encodedRawTxn).toString("hex");
    } catch (err) {
      throw err;
    }
  };

  const signTransaction = async (txn) => {
    try {
      if (txn === "" || txn === undefined) {
        throw new Error("Transaction undefined.");
      }
      if (!connectedWallet) {
        throw new Error("Please connect wallet first.");
      }
      return TransportWebUSB.create().then(async (transport) => {
        listen((log) => console.log(log));
        const algorand = new Algorand(transport);
        const address = await algorand.getAddress(derivationPath);
        console.info(address.address);
        const retrieveTxn = Buffer.from(txn, "hex");
        // const decodedTxn = algosdk.decodeObj(retrieveTxn);
        // const retrieveRawTxn = algosdk.Transaction.from_obj_for_encoding(
        //   decodedTxn.txn
        // );
        const signedTxn = await algorand.sign(derivationPath, retrieveTxn);
        return Buffer.from(signedTxn.signature).toString("hex");
      });
    } catch (err) {
      throw err;
    }
  };

  const submitTransaction = async (txn, sig) => {
    try {
      if (txn === "" || txn === undefined) {
        throw new Error("Transaction undefined.");
      }

      const rawUnsignedTxn = Buffer.from(txn, "hex");
      const rawTxn = algosdk.decodeUnsignedTransaction(rawUnsignedTxn);
      const signedTxn = {
        sig: Buffer.from(sig, "hex"),
        txn: rawTxn.get_obj_for_encoding(),
      };

      const signedTxnBlob = algosdk.encodeObj(signedTxn);
      const sendTx = await algodClient.sendRawTransaction(signedTxnBlob).do();
      return sendTx.txId;
    } catch (err) {
      throw err;
    }
  };

  const handleRequest = async () => {
    try {
      setSubmitting(true);
      const encodedRawTxn = await generateAlgoOptInTransaction(assetId);
      console.log("Generated opt in transaction successfully!");
      const encodedSignature = await signTransaction(encodedRawTxn);
      console.log("Signed transaction successfully!");
      const txHash = await submitTransaction(encodedRawTxn, encodedSignature);
      setOutputTxn(txHash);
      setSuccessMessage(`Submitted transaction successfully!`);
      setSubmitting(false);
    } catch (err) {
      setSuccessMessage(err.message);
      setSubmitting(false);
    }
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setAssetId(newValue);
  };

  const handleChangeDerivationPath = (event) => {
    const newValue = event.target.value;
    setDerivationPath(newValue);
  };

  return (
    <>
      <Container maxWidth="100%" paperPadding={30} style={{ padding: "10px" }}>
        {successMessage && <Alert severity="info">{successMessage}</Alert>}
        {connectedWallet && (
          <Typography style={{ paddingLeft: "40px", paddingTop: "20px" }}>
            Connected Wallet: {connectedWallet}
          </Typography>
        )}
        <Typography variant="h3" style={{ padding: "40px" }}>
          Opt In
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Asset ID</Typography>
          <TextField
            value={assetId}
            onChange={handleChange}
            name="assetId"
            placeholder="Asset ID"
            style={{ width: "800px" }}
            required
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Derivation Path:</Typography>
          <TextField
            value={derivationPath}
            onChange={handleChangeDerivationPath}
            name="derivationPath"
            placeholder="Derivation Path"
            style={{ width: "800px" }}
            required
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <LoadingButton
            variant="contained"
            fullWidth
            size="large"
            loading={submitting}
            onClick={handleConnection}
            style={{ backgroundColor: "#282c34" }}
          >
            Connect Ledger
          </LoadingButton>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <LoadingButton
            variant="contained"
            fullWidth
            size="large"
            loading={submitting}
            onClick={handleRequest}
            style={{ backgroundColor: "#282c34" }}
          >
            Generate and Sign Opt In Transaction
          </LoadingButton>
        </Stack>
        {outputTxn && (
          <>
            <Typography
              variant="h6"
              style={{ paddingLeft: "40px", paddingTop: "40px" }}
            >
              Confirmed Transaction Hash:
            </Typography>
            <Typography style={{ padding: "40px", overflowWrap: "break-word" }}>
              {outputTxn}
            </Typography>
          </>
        )}
      </Container>
    </>
  );
};

export default OptIn;
