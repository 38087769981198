const NETWORK = process.env.REACT_APP_BASE_SERVER.includes("mainnet")
  ? "MAINNET"
  : "TESTNET";

let mparamsQCADIssuer;
let mparamsQCADManager;
let mparamsKCADIssuer;
let mparamsKCADManager;

if (NETWORK === "MAINNET") {
  // Issuer Multisig = 3 of 5 multisig account
  const PK1 = "KIQCMFWFNT23EKZO6ISVW43IBLURGYLHPOATUKWC4YVW2OSJDFDTTHU7WE";
  const PK2 = "CUVU42ZK7L47T3AFKXFNFTPIXTCLVED2IANPTURVHQJGMRZBM5L325NA3Y";
  const PK3 = "JP2V76LHXRWE7V6FDN3TQE5E2CZQR3VKJYPSQ35MWIRQZSMS2OVWXTK7CY";
  const PK4 = "GB4VMIHL7MZWEO5MB5RUAAP6R7754OFP456UNDMYTVJEPLZLAXHWYPCHDA";
  const PK5 = "5RMZRIRGCGZN6IKGIYVMFTP2SUJET6WIEWGK72I7WTC2E37DBQBCLAY3TU";

  const QCAD_ISSUER_CONFIG = [PK1, PK2, PK3, PK4, PK5];
  const KCAD_ISSUER_CONFIG = [PK3, PK2, PK4, PK1, PK5];

  mparamsQCADIssuer = {
    version: 1,
    threshold: 3,
    addrs: QCAD_ISSUER_CONFIG,
  };

  mparamsKCADIssuer = {
    version: 1,
    threshold: 3,
    addrs: KCAD_ISSUER_CONFIG,
  };

  // Manager Multisig = 3 of 5 multisig account
  const MPK1 = "EAIWEVRQEGREMKLTOQZKCXG5CUQJBNOLSHWJYIKVX5TEB6CNU4WGI6T6FU";
  const MPK2 = "BPMGJ7GK7ZZLXSP3NGS5UMWFMKSMXCCGQ3OW2R7YYHP3EZM7LBU3W5PTSI";
  const MPK3 = "ZNJVYDONP3PD72WFWZRUA56AC2T7SB25UF5HNFU2HJG62SSJSQSTAJUJ4M";
  const MPK4 = "CFXONIKY5K5THN7UY4IWAB4F2E3R3ASDEJWIR3HH4OWSWFAEJVST2CTHUM";
  const MPK5 = "XHIAJ3HPVJKP53BLP43QYT2S274UR4JKU3QXBKLWI4DBFS6KWGEDIGGX6Q";

  const QCAD_MANAGER_CONFIG = [MPK1, MPK2, MPK3, MPK4, MPK5];
  const KCAD_MANAGER_CONFIG = [MPK3, MPK2, MPK4, MPK1, MPK5];

  mparamsQCADManager = {
    version: 1,
    threshold: 3,
    addrs: QCAD_MANAGER_CONFIG,
  };

  mparamsKCADManager = {
    version: 1,
    threshold: 3,
    addrs: KCAD_MANAGER_CONFIG,
  };
} else if (NETWORK === "TESTNET") {
  // Issuer Multisig = 2 of 3 multisig account
  const PK1 = "JDZIWFFVCMS6N4VUZ42ZVSBU7X76S42ILRQAGOX43QCIKRNFYMAJMGLM3Q";
  const PK2 = "U2KEXTM7HUBUL35L4SQKO5GUBSQJUBCJ5ZTRDTJ6UOTBKENI7JHKHKRFNM";
  const PK3 = "KH2623A36T7CEHLUVVI5AIDVTIQ3P3V7GV3EYD4VXGKAJZHRNDECSLA2TA";

  const QCAD_ISSUER_CONFIG = [PK1, PK2, PK3];
  const KCAD_ISSUER_CONFIG = [PK3, PK2, PK1];

  mparamsQCADIssuer = {
    version: 1,
    threshold: 2,
    addrs: QCAD_ISSUER_CONFIG,
  };

  mparamsKCADIssuer = {
    version: 1,
    threshold: 2,
    addrs: KCAD_ISSUER_CONFIG,
  };

  const QCAD_MANAGER_CONFIG = [PK1, PK2, PK3];
  const KCAD_MANAGER_CONFIG = [PK3, PK2, PK1];

  // Manager Multisig = 1 of 3 multisig account
  mparamsQCADManager = {
    version: 1,
    threshold: 1,
    addrs: QCAD_MANAGER_CONFIG,
  };

  mparamsKCADManager = {
    version: 1,
    threshold: 1,
    addrs: KCAD_MANAGER_CONFIG,
  };
}

export {
  mparamsQCADIssuer,
  mparamsQCADManager,
  mparamsKCADIssuer,
  mparamsKCADManager,
};
