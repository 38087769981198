import React from "react";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Home,
  Mint,
  Sign,
  CreateAsset,
  Transfer,
  Submit,
  SubmitTestnet,
  OptIn,
  // DestroyAsset,
} from "./pages";

function App() {
  window.Buffer = window.Buffer || require("buffer").Buffer;
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/transfer" element={<Transfer />} />
        <Route path="/mint" element={<Mint />} />
        <Route path="/sign" element={<Sign />} />
        <Route path="/create-asset" element={<CreateAsset />} />
        <Route path="/submit" element={<Submit />} />
        <Route path="/submit-test" element={<SubmitTestnet />} />
        <Route path="/opt-in" element={<OptIn />} />
        {/* Commented out to be safe but working fine */}
        {/* <Route path="/destroy-asset" element={<DestroyAsset />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
