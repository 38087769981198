import React from "react";
import { Nav, NavLink, NavMenu } from "./NavbarElements";
import logo from "../logo.svg";

const Navbar = () => {
  return (
    <>
      <Nav>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#ffffff",
          }}
        >
          <img src={logo} alt="logo" width="50px" height="50px" />
          <h2 style={{ paddingLeft: "10px" }}>QCAD</h2>
        </div>
        <NavMenu>
          <NavLink to="/" activeStyle>
            Home
          </NavLink>
          <NavLink to="/transfer" activeStyle>
            Transfer
          </NavLink>
          <NavLink to="/create-asset" activeStyle>
            Create Asset
          </NavLink>
          <NavLink to="/opt-in" activeStyle>
            Opt In
          </NavLink>
          <NavLink to="/mint" activeStyle>
            Mint
          </NavLink>
          {/* Commented out to be safe but working fine */}
          {/* <NavLink to="/destroy-asset" activeStyle>
            Destroy Asset
          </NavLink> */}
          <NavLink to="/sign" activeStyle>
            Sign
          </NavLink>
          {process.env.REACT_APP_BASE_SERVER.includes("mainnet") ? (
            <NavLink to="/submit" activeStyle>
              Submit
            </NavLink>
          ) : (
            <NavLink to="/submit-test" activeStyle>
              Submit
            </NavLink>
          )}
          <small>
            Network:{" "}
            {process.env.REACT_APP_BASE_SERVER.includes("mainnet")
              ? "Mainnet"
              : "Testnet"}
          </small>
        </NavMenu>
      </Nav>
    </>
  );
};

export default Navbar;
