import React, { useState } from "react";
import Container from "../components/Container";
import {
  Typography,
  Stack,
  TextField,
  Alert,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import algodClient from "../config/initAlgodClient";
import algosdk from "algosdk";
import LoadingButton from "@mui/lab/LoadingButton";
import { Buffer } from "buffer";
import { mparamsQCADIssuer, mparamsKCADIssuer } from "../config/multisigs";

const Submit = () => {
  window.Buffer = window.Buffer || require("buffer").Buffer;
  const [values, setValues] = useState({
    rawTxn: "",
    sig1: "",
    sig2: "",
    sig3: "",
    sig4: "",
    sig5: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [outputTxn, setOutputTxn] = useState("");
  const [sequenceLabel, setSequenceLabel] = useState([
    "(KIQ...)",
    "(CUV...)",
    "(JP2...)",
    "(GB4...)",
    "(5RM...)",
  ]);
  const QCADConfig = {
    issuer: algosdk.multisigAddress(mparamsQCADIssuer),
    sequenceLabel: ["(KIQ...)", "(CUV...)", "(JP2...)", "(GB4...)", "(5RM...)"],
  };
  const KCADConfig = {
    issuer: algosdk.multisigAddress(mparamsKCADIssuer),
    sequenceLabel: ["(JP2...)", "(CUV...)", "(GB4...)", "(KIQ...)", "(5RM...)"],
  };
  const [issuerAddress, setIssuerAddress] = useState(QCADConfig.issuer);

  const switchConfiguration = (event) => {
    if (event.target.value === QCADConfig.issuer) {
      setSequenceLabel(QCADConfig.sequenceLabel);
      setIssuerAddress(QCADConfig.issuer);
      setValues({
        rawTxn: "",
        sig1: "",
        sig2: "",
        sig3: "",
        sig4: "",
        sig5: "",
      });
      setSuccessMessage("");
      setOutputTxn("");
    }
    if (event.target.value === KCADConfig.issuer) {
      setSequenceLabel(KCADConfig.sequenceLabel);
      setIssuerAddress(KCADConfig.issuer);
      setValues({
        rawTxn: "",
        sig1: "",
        sig2: "",
        sig3: "",
        sig4: "",
        sig5: "",
      });
      setSuccessMessage("");
      setOutputTxn("");
    }
  };

  const submitTransaction = async (txn, sig1, sig2, sig3, sig4, sig5) => {
    try {
      if (txn === "" || txn === undefined) {
        throw new Error("Please provide the generated transaction.");
      }

      const rawMsigTxn = Buffer.from(txn, "hex");
      const decodeMsigTxn = algosdk.decodeObj(rawMsigTxn);

      const decodeRawTxn = algosdk.Transaction.from_obj_for_encoding(
        decodeMsigTxn.txn
      );

      const SIG1 = Buffer.from(sig1, "hex");
      const SIG2 = Buffer.from(sig2, "hex");
      const SIG3 = Buffer.from(sig3, "hex");
      const SIG4 = Buffer.from(sig4, "hex");
      const SIG5 = Buffer.from(sig5, "hex");

      const signedTxn = {
        txn: decodeRawTxn.get_obj_for_encoding(),
        msig: {
          ...decodeMsigTxn.msig,
          subsig: [
            {
              ...decodeMsigTxn.msig.subsig[0],
              s: SIG1.length > 0 ? SIG1 : undefined,
            },
            {
              ...decodeMsigTxn.msig.subsig[1],
              s: SIG2.length > 0 ? SIG2 : undefined,
            },
            {
              ...decodeMsigTxn.msig.subsig[2],
              s: SIG3.length > 0 ? SIG3 : undefined,
            },
            {
              ...decodeMsigTxn.msig.subsig[3],
              s: SIG4.length > 0 ? SIG4 : undefined,
            },
            {
              ...decodeMsigTxn.msig.subsig[4],
              s: SIG5.length > 0 ? SIG5 : undefined,
            },
          ],
        },
      };

      const signedTxnBlob = algosdk.encodeObj(signedTxn);
      const sendTx = await algodClient.sendRawTransaction(signedTxnBlob).do();
      return sendTx.txId;
    } catch (err) {
      throw err;
    }
  };

  const handleRequest = async () => {
    try {
      setSubmitting(true);
      const txHash = await submitTransaction(
        values.rawTxn,
        values.sig1,
        values.sig2,
        values.sig3,
        values.sig4,
        values.sig5
      );
      setOutputTxn(txHash);
      setSuccessMessage(`Submitted transaction successfully!`);
      setSubmitting(false);
    } catch (err) {
      setSuccessMessage(err.message);
      setSubmitting(false);
    }
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValues({
      ...values,
      [event.target.name]: newValue,
    });
  };

  return (
    <>
      <Container maxWidth="100%" paperPadding={30} style={{ padding: "10px" }}>
        {successMessage && <Alert severity="info">{successMessage}</Alert>}
        <Typography variant="h3" style={{ padding: "40px" }}>
          Submit
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <FormControl fullWidth>
            <InputLabel id="select-config">Configuration</InputLabel>
            <Select
              labelId="select-config"
              id="config"
              value={issuerAddress}
              label="Configuration"
              onChange={switchConfiguration}
            >
              <MenuItem value={QCADConfig.issuer}>{QCADConfig.issuer}</MenuItem>
              <MenuItem value={KCADConfig.issuer}>{KCADConfig.issuer}</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Typography variant="h6" style={{ paddingLeft: "40px" }}>
          Import Raw Transaction:
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="40px"
        >
          <TextField
            value={values.rawTxn}
            onChange={handleChange}
            name="rawTxn"
            placeholder="Encoded Transaction Hex"
            style={{ width: "800px" }}
            required
          />
        </Stack>
        <Typography variant="h6" style={{ paddingLeft: "40px" }}>
          Signatures (please follow the sequence)
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Signature 1 {sequenceLabel[0]}</Typography>
          <TextField
            value={values.sig1}
            onChange={handleChange}
            name="sig1"
            placeholder="Signature 1 Hex"
            style={{ width: "800px" }}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Signature 2 {sequenceLabel[1]}</Typography>
          <TextField
            value={values.sig2}
            onChange={handleChange}
            name="sig2"
            placeholder="Signature 2 Hex"
            style={{ width: "800px" }}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Signature 3 {sequenceLabel[2]}</Typography>
          <TextField
            value={values.sig3}
            onChange={handleChange}
            name="sig3"
            placeholder="Signature 3 Hex"
            style={{ width: "800px" }}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Signature 4 {sequenceLabel[3]}</Typography>
          <TextField
            value={values.sig4}
            onChange={handleChange}
            name="sig4"
            placeholder="Signature 4 Hex"
            style={{ width: "800px" }}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Signature 5 {sequenceLabel[4]}</Typography>
          <TextField
            value={values.sig5}
            onChange={handleChange}
            name="sig5"
            placeholder="Signature 5 Hex"
            style={{ width: "800px" }}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <LoadingButton
            variant="contained"
            fullWidth
            size="large"
            loading={submitting}
            onClick={handleRequest}
            style={{ backgroundColor: "#282c34" }}
          >
            Submit Transaction
          </LoadingButton>
        </Stack>
        {outputTxn && (
          <>
            <Typography
              variant="h6"
              style={{ paddingLeft: "40px", paddingTop: "40px" }}
            >
              Confirmed Transaction Hash:
            </Typography>
            <Typography style={{ padding: "40px", overflowWrap: "break-word" }}>
              {outputTxn}
            </Typography>
          </>
        )}
      </Container>
    </>
  );
};

export default Submit;
