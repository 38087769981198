import React, { useState } from "react";
import Container from "../components/Container";
import { Typography, Stack, TextField, Alert } from "@mui/material";
import algosdk from "algosdk";
import LoadingButton from "@mui/lab/LoadingButton";
import { Buffer } from "buffer";
import TransportWebUSB from "@ledgerhq/hw-transport-webusb";
import Algorand from "@ledgerhq/hw-app-algorand";
import { listen } from "@ledgerhq/logs";

const Sign = () => {
  window.Buffer = window.Buffer || require("buffer").Buffer;
  const [txn, setTxn] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [outputTxn, setOutputTxn] = useState("");
  const [connectedWallet, setConnectedWallet] = useState("");
  const [derivationPath, setDerivationPath] = useState("44'/283'/0'/0/0");

  const signTransaction = async (txn) => {
    try {
      if (txn === "" || txn === undefined) {
        throw new Error("Please provide the generated transaction.");
      }
      if (!connectedWallet) {
        throw new Error("Please connect wallet first.");
      }
      return TransportWebUSB.create().then(async (transport) => {
        listen((log) => console.log(log));
        const algorand = new Algorand(transport);
        const address = await algorand.getAddress(derivationPath);
        console.info(address.address);
        const retrieveTxn = Buffer.from(txn, "hex");
        const decodedTxn = algosdk.decodeObj(retrieveTxn);
        const retrieveRawTxn = algosdk.Transaction.from_obj_for_encoding(
          decodedTxn.txn
        );
        const signedTxn = await algorand.sign(
          derivationPath,
          retrieveRawTxn.toByte()
        );
        return Buffer.from(signedTxn.signature).toString("hex");
      });
    } catch (err) {
      throw err;
    }
  };

  const handleRequest = async () => {
    try {
      setSubmitting(true);
      const encodedSignedTxn = await signTransaction(txn);
      setOutputTxn(encodedSignedTxn);
      setSuccessMessage(`Signed transaction successfully!`);
      setSubmitting(false);
    } catch (err) {
      setSuccessMessage(err.message);
      setSubmitting(false);
    }
  };

  const handleConnection = async () => {
    try {
      setSubmitting(true);
      TransportWebUSB.create().then(async (transport) => {
        listen((log) => console.log(log));
        const algorand = new Algorand(transport);
        const { address } = await algorand.getAddress(derivationPath, true);
        setConnectedWallet(address);
      });
      setSubmitting(false);
    } catch (err) {
      setSuccessMessage(err.message);
      setSubmitting(false);
    }
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setTxn(newValue);
  };

  const handleChangeDerivationPath = (event) => {
    const newValue = event.target.value;
    setDerivationPath(newValue);
  };

  return (
    <>
      <Container maxWidth="100%" paperPadding={30} style={{ padding: "10px" }}>
        {successMessage && <Alert severity="info">{successMessage}</Alert>}
        {connectedWallet && (
          <Typography style={{ paddingLeft: "40px", paddingTop: "20px" }}>
            Connected Wallet: {connectedWallet}
          </Typography>
        )}
        <Typography variant="h3" style={{ padding: "40px" }}>
          Sign
        </Typography>
        <Typography variant="h6" style={{ paddingLeft: "40px" }}>
          Import Raw Transaction:
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <TextField
            value={txn}
            onChange={handleChange}
            name="txn"
            placeholder="Encoded Raw Transaction Hex"
            style={{ width: "800px" }}
            required
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Derivation Path:</Typography>
          <TextField
            value={derivationPath}
            onChange={handleChangeDerivationPath}
            name="derivationPath"
            placeholder="Derivation Path"
            style={{ width: "800px" }}
            required
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <LoadingButton
            variant="contained"
            fullWidth
            size="large"
            loading={submitting}
            onClick={handleConnection}
            style={{ backgroundColor: "#282c34" }}
          >
            Connect Ledger
          </LoadingButton>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <LoadingButton
            variant="contained"
            fullWidth
            size="large"
            loading={submitting}
            onClick={handleRequest}
            style={{ backgroundColor: "#282c34" }}
          >
            Sign Transaction
          </LoadingButton>
        </Stack>
        {outputTxn && (
          <>
            <Typography
              variant="h6"
              style={{ paddingLeft: "40px", paddingTop: "40px" }}
            >
              Output Signature:
            </Typography>
            <Typography style={{ padding: "40px", overflowWrap: "break-word" }}>
              {outputTxn}
            </Typography>
          </>
        )}
      </Container>
    </>
  );
};

export default Sign;
