import React from "react";
import { Alert, Container } from "@mui/material";
import * as S from "./Container.styles";
import { Box } from "@mui/system";

const CustomContainer = ({
  maxWidth,
  title,
  error,
  success,
  paperPadding,
  marginTop,
  marginBottom,
  children,
}) => {
  return (
    <Container
      maxWidth={false}
      sx={{
        maxWidth: maxWidth || "550px",
        marginTop: marginTop || "90px",
        marginBottom: marginBottom || "60px",
      }}
    >
      {title && (
        <S.Title variant="h4" textAlign="center">
          {title}
        </S.Title>
      )}
      <S.StyledPaper variant="card" paperPadding={paperPadding}>
        {(error || success) && (
          <Box mb="24px" maxWidth="550px">
            <Alert
              sx={{ wordBreak: "break-all" }}
              severity={error ? "error" : "success"}
            >
              {error || success}
            </Alert>
          </Box>
        )}
        {children}
      </S.StyledPaper>
    </Container>
  );
};

export default CustomContainer;
