import React, { useState } from "react";
import Container from "../components/Container";
import {
  Typography,
  Stack,
  TextField,
  Alert,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import algodClient from "../config/initAlgodClient";
import algosdk from "algosdk";
import LoadingButton from "@mui/lab/LoadingButton";
import { Buffer } from "buffer";
import {
  mparamsQCADIssuer,
  mparamsQCADManager,
  mparamsKCADIssuer,
  mparamsKCADManager,
} from "../config/multisigs";

const CreateAsset = () => {
  window.Buffer = window.Buffer || require("buffer").Buffer;
  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [outputTxn, setOutputTxn] = useState("");
  const [assetName, setAssetName] = useState("");
  const [issuerConfig, setIssuerConfig] = useState("QCAD");
  const QCADConfig = {
    issuer: algosdk.multisigAddress(mparamsQCADIssuer),
    manager: algosdk.multisigAddress(mparamsQCADManager),
  };
  const KCADConfig = {
    issuer: algosdk.multisigAddress(mparamsKCADIssuer),
    manager: algosdk.multisigAddress(mparamsKCADManager),
  };
  const [issuerAddress, setIssuerAddress] = useState(QCADConfig.issuer);
  const [managerAddress, setManagerAddress] = useState(QCADConfig.manager);

  const switchConfiguration = (event) => {
    if (event.target.value === QCADConfig.issuer) {
      setIssuerConfig("QCAD");
      setIssuerAddress(QCADConfig.issuer);
      setManagerAddress(QCADConfig.manager);
      setAssetName("");
      setSuccessMessage("");
      setOutputTxn("");
    }
    if (event.target.value === KCADConfig.issuer) {
      setIssuerConfig("KCAD");
      setIssuerAddress(KCADConfig.issuer);
      setManagerAddress(KCADConfig.manager);
      setAssetName("");
      setSuccessMessage("");
      setOutputTxn("");
    }
  };

  const generateCreateAssetTxn = async () => {
    try {
      const params = await algodClient.getTransactionParams().do();

      // generate raw txn to create asset
      const rawTxn = algosdk.makeAssetCreateTxnWithSuggestedParams(
        issuerAddress,
        undefined,
        // eslint-disable-next-line no-undef
        BigInt(18446744073709551615n),
        2,
        false,
        managerAddress,
        issuerAddress,
        issuerAddress,
        undefined,
        assetName,
        assetName,
        "https://www.stablecorp.ca/",
        undefined,
        params
      );
      const mparamsIssuer =
        issuerConfig === "QCAD" ? mparamsQCADIssuer : mparamsKCADIssuer;
      const encodedRawTxn = algosdk.createMultisigTransaction(
        rawTxn,
        mparamsIssuer
      );
      return Buffer.from(encodedRawTxn).toString("hex");
    } catch (err) {
      throw err;
    }
  };

  const handleRequest = async () => {
    try {
      setSubmitting(true);
      if (!assetName) {
        throw new Error("Invalid Asset Name. Please provide asset name.");
      }
      const encodedRawTxn = await generateCreateAssetTxn();
      setOutputTxn(encodedRawTxn);
      setSuccessMessage(`Generated create asset transaction successfully!`);
      setSubmitting(false);
    } catch (err) {
      setSuccessMessage(err.message);
      setSubmitting(false);
    }
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setAssetName(newValue);
  };

  return (
    <>
      <Container maxWidth="100%" paperPadding={30} style={{ padding: "10px" }}>
        {successMessage && <Alert severity="info">{successMessage}</Alert>}
        <Typography variant="h3" style={{ padding: "40px" }}>
          Create Asset
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <FormControl fullWidth>
            <InputLabel id="select-config">Configuration</InputLabel>
            <Select
              labelId="select-config"
              id="config"
              value={issuerAddress}
              label="Configuration"
              onChange={switchConfiguration}
            >
              <MenuItem value={QCADConfig.issuer}>{QCADConfig.issuer}</MenuItem>
              <MenuItem value={KCADConfig.issuer}>{KCADConfig.issuer}</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>From Address (Issuer)</Typography>
          <Typography>{issuerAddress}</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Note</Typography>
          <Typography>undefined</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Total Supply</Typography>
          <Typography>184467440737095516.15</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Decimals</Typography>
          <Typography>2</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Default Frozen</Typography>
          <Typography>false</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Manager</Typography>
          <Typography>{managerAddress}</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Reserve</Typography>
          <Typography>{issuerAddress}</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Freeze</Typography>
          <Typography>{issuerAddress}</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Clawback</Typography>
          <Typography>undefined</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Unit Name</Typography>
          <Typography>{assetName}</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Asset Name</Typography>
          <TextField
            value={assetName}
            onChange={handleChange}
            name="assetName"
            placeholder="Asset Name"
            style={{ width: "800px" }}
            required
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Asset URL</Typography>
          <Typography>https://www.stablecorp.ca/</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <Typography>Asset Metadata Hash</Typography>
          <Typography>undefined</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX="40px"
          paddingY="20px"
        >
          <LoadingButton
            variant="contained"
            fullWidth
            size="large"
            loading={submitting}
            onClick={handleRequest}
            style={{ backgroundColor: "#282c34" }}
          >
            Generate Create Asset Transaction
          </LoadingButton>
        </Stack>
        {outputTxn && (
          <>
            <Typography
              variant="h6"
              style={{ paddingLeft: "40px", paddingTop: "40px" }}
            >
              Output Transaction:
            </Typography>
            <Typography style={{ padding: "40px", overflowWrap: "break-word" }}>
              {outputTxn}
            </Typography>
          </>
        )}
      </Container>
    </>
  );
};

export default CreateAsset;
