import React from "react";
import "./Home.css";
import logo from "../logo.svg";

const Home = () => {
  window.Buffer = window.Buffer || require("buffer").Buffer;
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p style={{ fontSize: "30px", fontWeight: "bold" }}>QCAD</p>
        by
        <a
          className="App-link"
          href="https://www.stablecorp.ca/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Stablecorp
        </a>
      </header>
    </div>
  );
};

export default Home;
